/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";

import image from "assets/img/clint-mckoy.jpg";
import constants from "constants/constants.js"

const useStyles = makeStyles(blogPostPageStyle);

export default function PrivacyPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
      <div>
          <Header
              absolute
              color="transparent"
              brand={constants.BRAND}
              links={<HeaderLinks dropdownHoverColor="dark"/>}
              {...rest}
          />
          <div
              className={classes.pageHeader}
              // style={{
              //   backgroundImage: "url(" + image + ")",
              //   backgroundSize: "cover",
              //   backgroundPosition: "top center",
              // }}
          >
              {/* <div className={classes.container}> */}
              <div>
                  <GridContainer>
                      <GridItem md={12}>
                          <h1 className={classes.title}>Privacy Policy</h1>
                          <h2 className={classes.subTitle}>Last updated January 9, 2025</h2>
                          <h4 className={classes.description}>
                              This Privacy Policy explains how the “callsigns” application (“the App”) developed by
                              Young Enterprise Solutions, LLC (“we,” “us,” or “our”) handles information. By using the
                              App, you agree to the terms of this Privacy Policy. If you do not agree with these terms,
                              please refrain from using the App.
                          </h4>

                          {/* BEGIN: Full Privacy Policy Text */}
                          <h2>1. Information We Collect</h2>

                          <h3>1.1 Personal Information</h3>
                          <p>
                              We do <strong>not</strong> collect, transmit, or store any personal information
                              (such as name, email address, phone number, or any other uniquely identifiable
                              information) from users of the App.
                          </p>

                          <h3>1.2 Non-Personal Information</h3>
                          <ul>
                              <li>
                                  <strong>Information collected automatically:</strong> When the App communicates with resources, such as our
                                  website to retrieve license records, some automatically collected,
                                  non-personalized information (such as IP addresses, browser type,
                                  referring/exit pages, and timestamps) may be logged by the hosting
                                  service. These logs are collected to help us understand website traffic
                                  and usage patterns.
                              </li>
                              <li>
                                  <strong>Third-Party APIs:</strong> The App may communicate with the{" "}
                                  <em>data.fcc.gov</em> API to retrieve certain data. Any data collection,
                                  logging, or usage by the <em>data.fcc.gov</em> API is governed by{" "}
                                  <em>data.fcc.gov</em>’s own privacy policies. We encourage you to review
                                  the <a href="https://www.fcc.gov/privacy">privacy policy</a> of <em>data.fcc.gov</em> to understand their practices.
                              </li>
                          </ul>

                          <h2>2. How We Use Information</h2>
                          <p>
                              Since we do not collect personal information, there is no personal data
                              usage to disclose. However, any non-personalized data (such as aggregated
                              usage information) logged through our static website is used solely for
                              analytical or troubleshooting purposes.
                          </p>

                          <h2>3. Disclosure of Information</h2>
                          <p>
                              We do not sell, trade, or otherwise transfer personal information to
                              outside parties. In fact, we do not collect personal information at all.
                              Any limited non-personal data collected (e.g., IP addresses in server logs)
                              is not used to identify you or shared with third parties, except:
                          </p>
                          <ul>
                              <li>
                                  <strong>When Required by Law:</strong> We may disclose non-personal
                                  information if required to do so by law or in response to valid requests
                                  by public authorities (e.g., a court or a government agency).
                              </li>
                          </ul>

                          <h2>4. Third-Party Services</h2>
                          <p>
                              The App may contain links to or interactions with third-party websites or
                              APIs (including <em>data.fcc.gov</em>). We have no control over, and assume
                              no responsibility for, the content, privacy policies, or practices of any
                              third-party services. We strongly advise you to review <a href="https://www.fcc.gov/privacy">the Privacy Policy
                              of FCC website</a> and any other applicable policies.
                          </p>

                          <h2>5. Data Security</h2>
                          <p>
                              Although the App does not collect or store personal information, we strive
                              to use commercially acceptable and reasonable means to protect the non-personal
                              information (e.g., logs) stored by our web hosting service. However, no
                              method of transmission over the internet or method of electronic storage is
                              100% secure and reliable, and we cannot guarantee its absolute security.
                          </p>

                          <h2>6. Children’s Privacy</h2>
                          <p>
                              The App does not target anyone under the age of 13. We do not knowingly
                              collect personal information from children. If you are a parent or guardian
                              and believe your child has provided personal information, please contact us
                              so that we can promptly remove such data.
                          </p>

                          <h2>7. Changes to This Privacy Policy</h2>
                          <p>
                              We may update our Privacy Policy from time to time. Any changes will be
                              posted on this page with the updated date. Your continued use of the App
                              after any modifications to the Privacy Policy constitutes acceptance of
                              those changes.
                          </p>

                          <h2>8. Contact Us</h2>
                          <p>
                              If you have any questions about this Privacy Policy, please contact us at:
                          </p>
                          <p>
                              <strong>Young Enterprise Solutions, LLC</strong>
                              <br/>
                              info@yesolutions.io
                              <br/>
                          </p>

                          <p>
                              By using the “callsigns” App, you acknowledge that you have read and
                              understood this Privacy Policy. If you do not agree with the policies and
                              practices described here, please do not use the App.
                          </p>
                          {/* END: Full Privacy Policy Text */}
                      </GridItem>
                  </GridContainer>
              </div>
          </div>
          <Footer
              content={
                  <div>
                      <div className={classes.left}>
                          {/* Place your footer links or content here if needed */}
                      </div>
                      <div className={classes.right}>
                          &copy; {1900 + new Date().getYear()} , made with{" "}
                          <Favorite className={classes.icon}/> by YES
                      </div>
                  </div>
              }
          />
      </div>);
}
